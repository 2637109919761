import React from 'react';
import TextInput from './TextInput';
import SubmitButton from './SubmitButton';
import UserFormStateLifter from './UserFormStateLifter';
import Config from './Config';

export interface PasswordResetFormState {
	error: {
		field: string,
		message: string,
		type: string
	}
}

export default class PasswordResetForm extends React.Component<UserFormStateLifter, PasswordResetFormState> {
	public state = {error: {field: "", message: "", type: ""}};

	handleSubmit = (event : React.FormEvent) => {
		console.log("username", this.props.getUsername(), "password", this.props.getPassword(), "mail", this.props.getMail());

		const requestData = {
			"mailAddress": this.props.getMail(),
			"submit": true
		};
		const requestOptions = {
			method: 'POST',
			mode: "cors" as const,
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(requestData),
			credentials: "include" as const
		}

		fetch(Config.gameUrl + "/ajax/passwordRecovery/sendToken", requestOptions)
		.then((response) => {
			response.json().then(obj => {
				this.setState((state, props) => {
					return {error: obj};
				});
			});
		});

		event.preventDefault();
	}
 
	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				<dl className="inputFields">
					<TextInput
						inputType="email"
						id="mail"
						name="E-Mail-Adresse"
						onChange={this.props.setMail}
						initial={this.props.getMail}
					/>
				</dl>
				<SubmitButton name="Passwort verschicken" disabled={false} />

				<p className={`inputFeedback ${this.state.error.type}`}>
					{this.state.error.message}
				</p>
			</form>
		);
	}
}

