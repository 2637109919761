import React from 'react';
import LoginForm from './LoginForm';
import UserFormStateLifter from './UserFormStateLifter';
import { NavLink } from 'react-router-dom';


export default class LoginPage extends React.Component<UserFormStateLifter> {
	render() {
		return (
			<article className="loginPage">
				<h3>Willkommen bei <span className="lostworlds">Lost Worlds</span>!</h3>

				<section className="intro">
					<p>
						In Lost Worlds erschaffst du dir ein <b>Imperium im Weltall</b>. Du baust Minen, Flotte, kämpfst gegen deine Feinde und verteidigst deine Planeten. 
					</p>
				</section>
			
				<section className="loginForm"> 
					<LoginForm {...this.props}/>
				</section>
				

				<section className="registerTeaser">
					<p>Noch nicht dabei?</p>

					<NavLink to="/register" className="buttonLink">Registrieren</NavLink>
				</section>
			</article>
		);
	}
}