import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import Config from './Config';
import WotLoader from './WotLoader';

window.fetch(Config.gameUrl + "/runtime", {"credentials": "include"})
.then(response => {
	response.json().then(data => {
		if(!!data["loggedin"]) {
			const useMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase());
			WotLoader.load(!!useMobile);
		}
	});
});

const tag = document.createElement("link");
tag.setAttribute("rel", "stylesheet");
tag.setAttribute("href", Config.skinUrl + "/lw-home.css");
document.head.appendChild(tag);

ReactDOM.render(<App />, document.getElementById('react-root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
