import React from 'react';

export interface TextInputProps {
	id: string,
	name: string,
	inputType: "text" | "password" | "email",
	feedback: React.ReactNode | string | null
	initial: () => string,
	onChange: (value: string) => any,
	onBlur: () => any,
	feedbackType: string,
	children?: React.ReactNode;
}
export default class TextInput extends React.Component<TextInputProps, {focused : boolean, empty : boolean}> {
	static defaultProps = {
		feedback: null,
		initial: () => { return "" },
		feedbackType: "error",
		onBlur: () => null
	};

	public state = {focused: false, empty: true};

	handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const value = (event.target as any).value;

		this.setState((state, props) => {
			return {empty: (value === "")};
		})

		this.props.onChange(value);
	}

	onFocus = (event: React.FormEvent<HTMLInputElement>) => {
		this.setState((state, props) => {
			return {focused: true};
		})
	}

	onBlur = (event: React.FormEvent<HTMLInputElement>) => {
		const value = (event.target as any).value;

		this.setState((state, props) => {
			return {
				empty: (value === ""),
				focused: false
			};
		})
		this.props.onBlur();
	}

	render() {
		return (
			<div className={(this.state.focused || !this.state.empty) ? "inputContainer active" : "inputContainer inactive"}>
				<dt>
					<label htmlFor={this.props.id}>{this.props.name}</label>
					<span className="inputHint">{this.props.children}</span>
				</dt>
				<dd>
					<input
							name={this.props.id}
							type={this.props.inputType}
							onChange={this.handleChange} 
							onFocus={this.onFocus}
							onBlur={this.onBlur}
							value={this.props.initial()}
						/>
					<p className={`inputFeedback ${this.props.feedbackType}`}>
						{this.props.feedback}
					</p>
				</dd>
			</div>
		);
	}
}

