import React from 'react';
import PasswordResetForm from './PasswordResetForm';
import UserFormStateLifter from './UserFormStateLifter';

export default class PasswordResetPage extends React.Component<UserFormStateLifter> {
	render() {
		return (
			<article className="registerPage">
				<h2>Passwort zurücksetzen</h2>

				<section className="passwordResetForm"> 
					<PasswordResetForm {...this.props}/>
				</section>
			</article>
		);
	}
}