import React from 'react';

export default class CommunityPage extends React.Component {
	render() {
		return (
			<article className="communityPage">
				<h2>Community</h2>

				<section className="forum">
					<p>
						Das Forum wird zur gameweiten Kommunikation genutzt. Hier werden News angekündigt, Umfragen durchgeführt und viele wichtige Dinge besprochen. 
					</p>
					<p>
						<a href="https://forum.lost-worlds.net/" target="_blank" rel="noreferrer" className="buttonLink">Zum Forum</a>
					</p>
				</section>

				<section className="chat">
					<p>
						Im Discord kann man schnell kommunizieren, sowohl mit allen anderen Spielern, oder auch intern in einer Allianz oder direkt zwischen zwei Spielern. 
					</p>
					<p>
						<a href="https://discord.gg/3WbN9Af" target="_blank" rel="noreferrer" className="buttonLink">Zum Discord</a>
					</p>
				</section>
			</article>
		);
	}
}