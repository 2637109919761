import React from 'react';
import { BrowserRouter as Router, Route, NavLink, Routes, Navigate } from "react-router-dom";
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import LegalPage from './LegalPage';
import AboutPage from './AboutPage';
import CommunityPage from './CommunityPage';
import PasswordResetPage from './PasswordResetPage';

export class AppState {
	username = ""
	password = ""
	mail = ""

	getUsername = () => this.username;
	getPassword = () => this.password;
	getMail = () => this.mail;

	setUsername = (username : string) => this.username = username;
	setPassword = (password : string) => this.password = password;
	setMail = (mail : string) => this.mail = mail;

	mainNavActive = false;
}

export default class App extends React.Component<{}, AppState> {
	public state = new AppState();
	public mainNavRef = React.createRef<HTMLElement>();

	showMainNav = (event : React.MouseEvent) => {
		this.setState((state, props) => {
			return {mainNavActive: true};
		});
		event.stopPropagation();
	}
	hideMainNav = () => {
		this.setState((state, props) => {
			return {mainNavActive: false};
		});
	}

	render() {
		return (<div className="pageContainer" onClick={this.hideMainNav}>
			<Router>
				<header className="home">
					<NavLink to="/login">
						<div className="headerLogo" />
					</NavLink>
				</header>

				<aside className="home">
					<div className="hamburgerButton" role="button" aria-label="Open the navigation" onClick={this.showMainNav}>
						<span className="hamburgerLabel" aria-hidden="true">☰</span>
					</div>

					<nav className={`mainNav ${this.state.mainNavActive ? 'mainNavActive' : ''}`}>
						<ul>
							<li>
								<NavLink to="/"><span className="linkText">Anmelden</span></NavLink>
							</li>
							<li>
								<NavLink to="/register"><span className="linkText">Registrieren</span></NavLink>
							</li>
							<li>
								<NavLink to="/about"><span className="linkText">Über das Spiel</span></NavLink>
							</li>
							<li>
								<NavLink to="/community"><span className="linkText">Community</span></NavLink>
							</li>
						</ul>
					</nav>
				</aside>
		
				<main className="home">
					<Routes>
						<Route path="/" element={<LoginPage {...this.state} /> } />
						<Route path="/register" element={<RegisterPage {...this.state} /> } />
						<Route path="/about" element={<AboutPage />} />
						<Route path="/passwordReset" element={<PasswordResetPage {...this.state} /> } />
						<Route path="/legal" element={<LegalPage />} />
						<Route path="/community" element={<CommunityPage />} />
						<Route path="/*" element={<Navigate replace to="/" />} />
					</Routes>
				</main>

				<footer className="home">
					<nav className="bottomNav">
						<ul>
							<li>
								<NavLink to="/legal">Impressum</NavLink>
							</li>
						</ul>
					</nav>
				</footer>
			</Router>
		</div>);
	}
}
