import Config from './Config';

interface WotInterface {
	startup: () => any
}

declare let Wot : WotInterface;
declare let skinpath : string;
// eslint-disable-next-line
declare let config : any;
// eslint-disable-next-line
declare let settings : any;
// eslint-disable-next-line
declare let userId : number;
// eslint-disable-next-line
declare let apiUrl : string;
// eslint-disable-next-line
declare let modules : Array<string>;
// eslint-disable-next-line
declare let currentTime : number;
// eslint-disable-next-line
declare let numberInput : string;
declare let mainNavOrder : {[key:string]:string};
declare let bottomNavOrder : {[key:string]:string};

export default class WotLoader {
	static load(useMobile : boolean) {
		console.log("Starting login process");

		document.domain = Config.commonDomain;
		document.body.classList.add("lw-transition"); // add this before other style changes and allow browser to render in between

		// request runtime data
		window.fetch(Config.gameUrl + "/runtime", {"credentials": "include"})
		.then(response => {
			response.json().then(data => {
				console.log("Received runtime data", data);

				// set global configuration
				mainNavOrder = {};
				for(let key in data["mainNav"]) {
					mainNavOrder["pos" + data["mainNav"][key]] = key
				}
				bottomNavOrder = {};
				for(let key in data["bottomNav"]) {
					bottomNavOrder["pos" + data["bottomNav"][key]] = key
				}
				skinpath = data["skinPath"];
				config = data["config"];
				settings = data["settings"];
				userId = data["userId"];
				apiUrl = Config.gameUrl + "/ajax/";
				modules = [];
				currentTime = Date.now();
				numberInput = "number";

				// add stylesheet
				const t = Date.now();
				const styleTag = document.createElement("link");
				styleTag.setAttribute("rel", "stylesheet");
				styleTag.setAttribute("href", skinpath + "/" + (useMobile ? "mobile.css" : "desktop.css") + "?t=" + Date.now());
				document.head.appendChild(styleTag);

				// remove 'home' class
				document.body.classList.remove("lw-home");
				window.setTimeout(() => document.body.classList.remove("lw-transition"), 2000);

				// set 'mobile'/'desktop' class
				document.body.classList.add(useMobile ? "mobile" : "desktop");
		
				// add script
				const tag = document.createElement('script');
				tag.setAttribute('src', Config.webUrl + '/js/admin-all.js?t=' + t);
				tag.onload = () => Wot.startup(); // <-- startup
				document.head.appendChild(tag);
			});
		});
	}
}