import React from 'react';
import TextInput from './TextInput';
import SubmitButton from './SubmitButton';
import UserFormStateLifter from './UserFormStateLifter';
import Config from './Config';
import WotLoader from './WotLoader';

export interface RegisterFormState {
	registrationStatus: string,
	error: {
		field: string,
		message: string,
		type: string
	}
}

export default class RegisterForm extends React.Component<UserFormStateLifter, RegisterFormState> {
	public state = {registrationStatus: "not_started", error: {field: "", message: "", type: ""}};
	public useMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase());

	update = (submit = false) => {
		console.log("username", this.props.getUsername(), "password", this.props.getPassword(), "mail", this.props.getMail());

		const requestData = {
			"username": this.props.getUsername(),
			"password": this.props.getPassword(),
			"mailAddress": this.props.getMail(),
			"submit": submit
		};
		if(submit) requestData["submit"] = true;
		const requestOptions = {
			method: 'POST',
			mode: "cors" as const,
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(requestData),
			credentials: "include" as const
		};

		window.fetch(Config.gameUrl + "/ajax/register", requestOptions)
		.then((response) => {
			response.json().then(obj => {
				if(!submit || !obj.hasOwnProperty("success")) {
					// if no submit or submit with errors: simply save the result
					this.setState((state, props) => {
						return {error: obj};
					});
				}
				else {
					WotLoader.load(this.useMobile);
				}
			});
		});
	}

	handleSubmit = (event : React.FormEvent) => {
		this.update(true);

		event.preventDefault();
	}
 
	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				<dl className="inputFields">
					<TextInput
						inputType="email"
						id="mail"
						name="E-Mail-Adresse"
						onChange={this.props.setMail}
						onBlur={this.update}
						initial={this.props.getMail}
					/>
					<TextInput
						inputType="text"
						id="username"
						name="Spielername"
						onChange={this.props.setUsername}
						onBlur={this.update}
						initial={this.props.getUsername}
					/>
					<TextInput
						inputType="password"
						id="password"
						name="Passwort"
						onChange={this.props.setPassword}
						onBlur={this.update}
						initial={this.props.getPassword}
					/>
				</dl>

				<SubmitButton name="Registrieren" disabled={false} />

				<p className={`inputFeedback ${this.state.error.type}`}>
					{this.state.error.message}
				</p>
			</form>
		);
	}
}

