import React from 'react';
import { NavLink } from "react-router-dom";
import TextInput from './TextInput';
import SubmitButton from './SubmitButton';
import UserFormStateLifter from './UserFormStateLifter';
import Config from './Config';
import WotLoader from './WotLoader';

export interface LoginFormState {
	loginStatus: string
}

export default class LoginForm extends React.Component<UserFormStateLifter, LoginFormState> {
	public state = {loginStatus: "not_started"};
	public useMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase());

	handleSubmit = (event : React.FormEvent) => {
		console.log("logging in as username: ", this.props.getUsername());

		const requestStr = "username=" + encodeURIComponent(this.props.getUsername().trim())
			+ "&password=" + encodeURIComponent(this.props.getPassword())
			+ "&mobileSwitch=" + (this.useMobile ? "true" : "false");
		const requestOptions = {
			method: 'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			body: requestStr,
			credentials: "include" as const
		};
		window.fetch(Config.gameUrl + "/loginCheckApi", requestOptions)
		.then(response => {
			response.json().then(obj => {
				if(obj.hasOwnProperty("success")) {
					this.executeLogin();
				}
				else {
					this.setState((props, state) => {return {
						loginStatus: obj["error"]
					}});
				}
			});
		});

		event.preventDefault();
	}

	executeLogin() {
		WotLoader.load(this.useMobile);
	}
 
	render() { 
		const usernameNotFound : React.ReactElement = <span>
			Der Name wurde nicht gefunden. Musst du dich vielleicht noch <NavLink to="/register">registrieren</NavLink>?
		</span>;
		const wrongPassword : React.ReactElement = <span>
			Das Passwort ist nicht korrekt. Wenn du es vergessen hast, kannst du dir <NavLink to="/passwordReset">ein neues zuschicken lassen</NavLink>.
		</span>;

		return (
			<form onSubmit={this.handleSubmit} method="post">
				<dl className="inputFields">
					<TextInput
						inputType="text"
						id="username"
						name="Spielername"
						onChange={this.props.setUsername}
						feedback={this.state.loginStatus === "not_found" ? usernameNotFound : null}
						feedbackType="error"
						initial={this.props.getUsername}
					/>
					<TextInput
						inputType="password"
						id="password"
						name="Passwort"
						onChange={this.props.setPassword}
						feedback={this.state.loginStatus === "wrong_password" ? wrongPassword : null}
						feedbackType="error"
						initial={this.props.getPassword} 
					>
						<NavLink to="/passwordReset" tabIndex={-1}> (vergessen?)</NavLink>
					</TextInput>
				</dl>

				<SubmitButton name="Einloggen" disabled={false} />
			</form>
		);
	}
}

