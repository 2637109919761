import React from 'react';

export default class AboutPage extends React.Component {
	render() {
		return (
			<article className="aboutPage">
				<h2>Über <span className="lostworlds">Lost Worlds</span></h2>

				<section className="about">
					<p>
						Vielleicht kennst du andere Browserspiele. Vielleicht auch andere Weltraumspiele.
						Aber was macht <span className="lostworlds">Lost Worlds</span> besonders?
						Das Spiel wurde von Spielern gegründet, die an einem anderen Spiel viel Freude hatten.
						Bis eines Tages die betreibende Firma es möglich machte, sich im Spiel Vorteile zu erkaufen, um mehr Geld mit dem Spiel zu verdienen.
						Dadurch entstand eine Zwei-Klassen-Gesellschaft: Spieler, die einfach so spielten, und solche, die viel Geld reinsteckten und sich „hochkauften“.
						Ein fairer Kampf wurde dadurch verhindert.
					</p>
					<p>
						Deswegen wurde <span className="lostworlds">Lost Worlds</span> gegründet:
						Es soll ein Spiel mit bewährten Spielideen sein, ohne dass sich Leute nach oben kaufen können.
						Hinter <span className="lostworlds">Lost Worlds</span> steht kein gewinnorientierter Konzern, sondern eine Gemeinschaft von Leuten, die einfach nur Spaß daran haben, zu spielen und Spiele zu machen.
					</p>
				</section>
			</article>
		);
	}
}