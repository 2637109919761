import React from 'react';

export default class LegalPage extends React.Component {
	render() {
		return (
			<article className="legalPage">
				<h2>Impressum</h2>

				<section className="address">
					<p className="name">David Wägner</p>
					<p className="street">Hummelsteiner Weg 60</p>
					<p className="city">90459 Nürnberg</p>
				</section>
				<section className="otherContacts">
					Weitere Kontaktmöglichkeiten:
					<ul>
						<li>Per E-Mail an <span className="mailAddress">impressum@lost-worlds.net</span></li>
						<li>Per Nachricht im Spiel an <span className="ingameNickname">Biggerskimo</span></li>
						<li>Im Chat an <span className="chatNickname">biggy</span> (sofern verfügbar)</li>
					</ul>
				</section>
			</article>
		);
	}
}