import React from 'react';

export interface SubmitButtonProps {
	name: string,
	disabled: boolean
}

export default class SubmitButton extends React.Component<SubmitButtonProps> {
	render() {
		return (
			<div className="submitButtonContainer">
				<input type="submit" value={this.props.name} disabled={this.props.disabled} />
			</div>
		);
	}
}