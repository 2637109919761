import React from 'react';
import RegisterForm from './RegisterForm';
import UserFormStateLifter from './UserFormStateLifter';

export default class RegisterPage extends React.Component<UserFormStateLifter> {
	render() {
		return (
			<article className="registerPage">
				<h2>Registrieren</h2>

				<section className="registerForm"> 
					<RegisterForm {...this.props}/>
				</section>
			</article>
		);
	}
}