const config = {
	commonDomain: "example.com",
	gameUrl: "http://game.example.com",
	webUrl: "http://web.example.com",
	skinUrl: "http://skin.example.com/wots2-q8"
};

["commonDomain", "gameUrl", "webUrl", "skinUrl"].forEach(setting => {
	console.log(setting, (window as any).lwHomeConfig, (window as any).lwHomeConfig[setting], (config as any)[setting]);

	const urlParams = new URLSearchParams(window.location.search);

	if(urlParams.has(setting)) {
		(config as any)[setting] = urlParams.get(setting);
	}
	else if((window as any).lwHomeConfig !== undefined && (window as any).lwHomeConfig[setting] !== undefined) {
		(config as any)[setting] = (window as any).lwHomeConfig[setting];
	}
})


export default config;